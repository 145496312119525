import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable()

export class DataStorageService {
  private data = [];
  dbCreatedStatus = false;

  constructor(private storage: Storage) {
    this.init();
  }

   async init(){
       try{
            await this.storage.create();
            this.dbCreatedStatus = true;
       }catch(err){
            console.log('no se pudo crea el local storage');
            this.dbCreatedStatus = false;
       }
   }

   checkDbCreatedStatus(){
        return this.dbCreatedStatus;
   }
  
  async get(dataName){
    return await this.storage.get(dataName);
  }

  async set(dataName,dataValue){
    await this.storage.set(dataName, dataValue);
  }

  async clear(){
    this.data = [];
    await this.storage.clear();
    console.log(this.data);
  }
  
  /*
  async clearByIndex(index){
    delete this.data[index];
    console.log(this.data);
  }*/
}