import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_services/authGuard.service';
import { InverseAuthGuard } from './_services/inverseAuthGuard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    canActivate: [InverseAuthGuard]
  },
  {
    path: 'inicio',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tienda',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'color-generator',
    loadChildren: () => import('./color-generator/color-generator.module').then( m => m.ColorGeneratorPageModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./product/product.module').then( m => m.ProductPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register/:token',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule),
    canActivate: [InverseAuthGuard]
  },
  {
    path: 'seller-image-config',
    loadChildren: () => import('./components/seller-image-config/seller-image-config.module').then( m => m.SellerImageConfigPageModule)
  },
  {
    path: 'help-info',
    loadChildren: () => import('./help-info/help-info.module').then( m => m.HelpInfoPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule),
    canActivate: [InverseAuthGuard]
  }
  /*
  {
    path: 'example',
    loadChildren: () => import('./example/example.module').then( m => m.ExamplePageModule)
  },
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
