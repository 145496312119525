import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()

export class FileService {
  private storageClient: string = 'digitalOcean';
  private url: string = environment.fileServer;
  public fileUploaded ;

  constructor(private http: HttpClient) { }

 
  sendFile(formData: FormData) {
    formData.append('privacy', 'public');
    formData.append('storageClient', this.storageClient)
    return this.http.post<any>(`${this.url}/file/createFile`, formData, {});
  }

  readFile(filePath: String) {
    const body = {
      storageClient: this.storageClient,
      storageData: {
        "filePath": filePath
      }
    }

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json'
    };

    return this.http.post<any>(`${this.url}/file/getFile`, body, options);
  }

  // readFile(formData){    
  //   return this.http.post<any>(`${this.url}/read`, formData, {  });
  //   //return this.http.post<any>(`${this.url}/read`, formData, { responseType: 'blob' });
  // }
}
