import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataStorageService } from './dataStorage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard{

    constructor(private router: Router, private dataStorage: DataStorageService) { }

    async canActivate(): Promise<boolean> {
        const session = await this.dataStorage.get('user');
        if(session) {
            return true
        }
        
        this.routeTo('login')
        return true
    }

    routeTo(route: string) {
        this.router.navigate([`/${route}`])
     }
}