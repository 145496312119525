import { Component, OnInit } from '@angular/core';
import { StoreService } from './_services/store.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    {
      title: 'Pedidos',
      url: '/pedidos',
      icon: 'receipt'
    },
    {
      title: 'Productos',
      url: '/productos',
      icon: 'cube'
    }
  ];
 
  public anotherPages = [
    {
      title: 'Mi Tienda',
      url: '/tienda',
      icon: 'storefront'
    }
  ]

  loadingLogo:boolean=true;
  logo;
  faviconUrl:string;
  tabTitle:string = 'Panel Admin';

  constructor(private storeService: StoreService) {}
  
  ngOnInit() {

    //set favicon
    this.storeService.getCommonConfigByEnterprise(environment.enterpriseId,'tab').subscribe(data=>{
      this.faviconUrl = data['props'].imgUrl;
      this.tabTitle = data['name'];
      document.getElementById('appFavicon').setAttribute('href', this.faviconUrl);
      document.getElementById("tabTitle").innerHTML = 'Panel Admin ' + this.tabTitle;
    },err=>{
      document.getElementById("tabTitle").innerHTML = this.tabTitle;
    })

    this.storeService.getCommonConfigByEnterprise(environment.enterpriseId, 'logo').subscribe( (data)=>{
      this.logo=data['props'];
      this.loadingLogo = false;
    }, err=>{
      this.loadingLogo = false;
    })
  }
  ngAfterViewInit(){
    //Ocultar sidemenu
    /*var menu = document.querySelector('ion-menu');
    menu.hidden = true;*/
  }
}
