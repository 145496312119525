import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStorageService } from './dataStorage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private token : string = "";
  private url: string = environment.admin;
  private enterprise : string = "";
  private user : string = "";
  private sellerId : string = "";

  constructor(private router:Router,private dataStorage : DataStorageService ,private http:HttpClient) {
    this.init()
  }
  
  async init(){
    var val = await this.dataStorage.get('user');
    
    if(val){
      console.log("val",val);
      this.setToken(val['token']);
      this.user = val;
      this.sellerId = val.sellerId;
    }
    else{
      //this.router.navigate(['/login'], {replaceUrl: true});
    }
  }

  public getUrl(){
    return new Promise(resolve => {
      resolve(environment.admin);
    });
  }
  
  public getEnterprise(){
    return this.enterprise;
  }  
  public setEnterprise(em){
    this.enterprise = em;
  }
  public setToken(t){
    this.token = t;
  }

  public setSeller(seller){
    console.log('set seller',seller)
    this.sellerId = seller;
  }
  public getToken(){
    return "Bearer "+this.token;
  }
  public setUser(user){
    this.dataStorage.set('user', user);
    this.user = user;
  }
  public getUserId(){
   return this.user['_id'];
  }

  public getSellerId(){
    
    return this.sellerId;
   }

  async getUser(form){
    this.url = <string>await this.getUrl();
    //this.url = "https://api.vase.cl";
    console.log('estoy dentro');

    return this.http.post<any[]>(`${this.url}/user/login/`,form , {
      headers: new HttpHeaders()
      .set('Authorization','Bearer '+this.token)
      .set('Content-Type', 'application/json')
    });
  }
}