import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()

export class DictionaryService {
  

  constructor() { }

  flattenObj(ob){
 
    // The object which contains the
    // final result
    let result = {};
 
    // loop through the object "ob"
    for (const i in ob) {
 
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
            const temp = this.flattenObj(ob[i]);
            for (const j in temp) {
 
                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        }
 
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};
  translateWord(key){
    switch (key) {
      case 'selectedButtonBorderBottom':
          return 'Color borde inferior del botón seleccionado'
        break;
      case 'barBorderBottom':
          return 'Color borde inferior de la barra'
        break;
      case 'buttonTextColor':
          return 'Color texto botón sin seleccionar'
        break;
      case 'buttonBackgroundColor':
          return 'Color de fondo del botón'
        break;
      case 'selectedButtonTextColor':
          return 'Color texto botón seleccionado'
        break;
      case 'sectionBackgroundColor':
          return 'Color de fondo de la sección'
        break;  
      case 'sectionTitleColor':
          return 'Color del título de la sección'
        break; 
      case 'sectionTitleDecoration':
          return 'Decorador de título de la sección'
        break;
      case 'columnTitleColor':
          return 'Color del titulo de la columna'
        break;    
        
      case 'maxColumns':
          return 'Máximo de columnas'
        break;       
      case 'title':
          return 'Título'
        break;       
      case 'type':
          return 'Tipo'
        break;   
      case 'columns':
          return 'Cantidad de columnas'
        break;
      case 'intervalTime':
          return 'Tiempo de intervalo entre diapositivas'
        break;            

      }

  }
}