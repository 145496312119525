import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {  
  private url: string = environment.admin;

  constructor(private http:HttpClient,private login:LoginService) {    
  }
  async list() {    

    return this.http.get<any[]>(`${this.url}/store/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  getOne(id){
    return this.http.get<any[]>(`${this.url}/store/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  getbyEnterprise(enterpriseId,pagetype){
    const store={
      enterpriseId,
      pagetype
    }

    return this.http.post<any[]>(`${this.url}/store/getByEnterprise/` ,store, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken()) 
    });
  }

  getCommonConfigByEnterprise(enterpriseId,componentName){
    const store={
      enterpriseId,
      componentName
    }

    return this.http.post<any[]>(`${this.url}/store/getCommonConfigByEnterprise/` ,store, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
     
    });
  }

  update(id,store){
    delete store.__v;
    return this.http.patch<any[]>(`${this.url}/store/${id}` , store,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  insert(store){
    store.status = true;
    return this.http.post<any[]>(`${this.url}/store/` ,store, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

}
