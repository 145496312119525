import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; //Protocolo http para que los servicios se comuniquen con la api
import { ReactiveFormsModule,FormsModule } from '@angular/forms'; //esto es para los formularios
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//servicios

import { AuthService } from './_services/auth.service';
import { LoginService } from './_services/login.service';
import { FileService } from './_services/file.service';
import { ValidationService } from './_services/validation.service';
import { DataStorageService } from './_services/dataStorage.service';
import { ExcelService } from './_services/excel.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { ColorGeneratorService } from './_services/colorGenerator.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { DictionaryService } from './_services/dictionary.service';
import { UtilitiesService } from './_services/utilities.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    ReactiveFormsModule,
     IonicModule.forRoot(),
      AppRoutingModule ,
      HttpClientModule, 
      IonicStorageModule.forRoot(), 
      ColorPickerModule, 
      IonicSelectableModule,
    ],
  providers: [
    AuthService,
    LoginService,
    FileService,
    ExcelService,
    DataStorageService,
    ValidationService,
    ColorGeneratorService,
    DictionaryService,
    UtilitiesService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  schemas :[CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
